@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;
//LIBRARY

// FOUNDATION
@import 'foundation/all';
@import 'foundation/variables';
@import 'foundation/mixin';
@import 'foundation/typography';
@import 'foundation/spacing';

@import 'utils/all';

// COMPONENTS & SECTIONS
@import 'foundation/layout';
@import 'components/all';
@import 'sections/all';

@font-face {
  font-family: 'Graceland';
  font-style: normal;
  font-display: auto;
  src: local('Graceland'), url('/fonts/Graceland.otf');
}

@font-face {
  font-family: 'Matthew';
  font-style: normal;
  font-display: auto;
  src: local('Graceland'), url('/fonts/Matthew.otf');
}

@font-face {
  font-family: 'Traveler';
  font-style: normal;
  font-display: auto;
  src: local('Traveler'), url('/fonts/Traveler.ttf');
}

// PAGES
@import 'pages/all';
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

// Define breakpoints
$breakpoints: (
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1536px,
);

// Simple media query mixin
@mixin respond-to($breakpoint) {
  @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
    @content;
  }
}

// Expose breakpoints
body::after {
  position: absolute;
  left: -1337px;
  visibility: hidden;
  user-select: none;
  pointer-events: none;
  opacity: 0;
  font-size: 0;
  z-index: -1;

  @each $breakpoint-key, $breakpoint-value in $breakpoints {
    @if ($breakpoint-key == 'sm') {
      content: 'sm';
    } @else {
      @include respond-to($breakpoint-key) {
        content: '#{$breakpoint-key}';
      }
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

b,
strong {
  @apply font-bold;
}

body {
  @apply font-alegreya;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll-indicator::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll-indicator {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  color: white;
}

input::-webkit-calendar-picker-indicator {
  background: transparent;
  color: white;
  cursor: pointer;
}
