@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;1,400;1,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Wix+Madefor+Display:wght@400;600&display=swap');

@font-face {
  font-family: VNA ApetorH;
  src: url('https://cdn.jsdelivr.net/gh/loctran1902/font/Vnaah.ttf')
    format('truetype');
}
@font-face {
  font-family: VNA ApetorH;
  font-weight: bold;
  src: url('https://cdn.jsdelivr.net/gh/loctran1902/font/Vnaahb.ttf')
    format('truetype');
}
@font-face {
  font-family: SFU Futura;
  font-weight: regular;
  src: url('https://cdn.jsdelivr.net/gh/loctran1902/font/SFUFuturaRegular.TTF')
    format('truetype');
}
@font-face {
  font-family: SFU Futura;
  font-weight: bold;
  src: url('https://cdn.jsdelivr.net/gh/loctran1902/font/SFUFuturaBold.TTF')
    format('truetype');
}

.font-apetorh {
  font-family: 'VNA ApetorH', -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

.font-futura {
  font-family: 'SFU Futura', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.font-wix-display {
  font-family: 'Wix Madefor Display', -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

html {
  @include sm() {
  }

  @include md() {
  }

  @include lg() {
  }

  @include xl() {
  }

  font-family: 'Wix Madefor Display', -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: var(--font-sans, 'Poppins', 'sans-serif');
  font-weight: var(--font-weight-bold);
}

h1 {
  font-size: calc(var(--t-heading-1, 2rem) * var(--font-multiplier-100, 1));
}

h2 {
  font-size: calc(var(--t-heading-2, 1.5rem) * var(--font-multiplier-100, 1));
}

h3 {
  font-size: calc(var(--t-heading-3, 1.5rem) * var(--font-multiplier-100, 1));
}

h4 {
  font-size: calc(var(--t-heading-4, 1.5rem) * var(--font-multiplier-100, 1));
}

h5 {
  font-size: calc(var(--t-heading-5, 1.5rem) * var(--font-multiplier-100, 1));
}

p {
  font-size: calc(var(--t-body, 1rem) * var(--font-multiplier-100, 1));
  line-height: var(--line-height-150, 1.5);
}

a {
  &:hover {
    @apply text-primary;
  }

  &:active {
    @apply text-opacity-95;
  }
}

.post-title {
  @apply text-2xl md:text-3xl xl:text-4xl 2xl:text-5xl font-wix;
}

.post-title__home {
  @apply text-3xl md:text-6xl xl:text-7xl font-alegreya uppercase font-normal italic;
}

.section-title-lv1 {
  @apply text-3xl md:text-4xl 2xl:text-5xl;
}

.section-subtitle {
  @apply text-xl md:text-2xl;
}
