.hover-to-show-trigger {
  &:hover {
    .hover-to-show {
      opacity: 1;
    }

    .hover-to-show__show-on-mobile {
      opacity: 1;
    }
  }
}

.hover-to-show {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.hover-to-show__show-on-mobile {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  @include sm {
    opacity: 1;
  }
}

//@screen lg {
//  /* ... */
//  .hover-to-show__show-on-mobile {
//    opacity: 1;
//    transition: opacity 0.3s ease-in-out;
//  }
//}

//@responsive {
//  /* MOBILE */
//  .hover-to-show__show-on-mobile {
//    opacity: 1;
//    transition: opacity 0.3s ease-in-out;
//  }
//
//  /* TABLE */
//  .hover-to-show__show-on-mobile {
//    opacity: 0;
//    transition: opacity 0.3s ease-in-out;
//  }
//}
