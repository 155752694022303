.main-content {
  max-width: 1440px;
  position: relative;
  margin: auto;
}

.vertical-border {
  width: 1px;
  height: inherit;
  @apply bg-gray-300;
}
