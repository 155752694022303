.small-content__wrapper {
  width: 650px;

  @include tablet {
    width: auto;
  }
  @apply m-auto;
}

.gallery {
  &__content {
    z-index: 1;
    border: 1px solid rgb(221, 221, 221);
    box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
    @apply text-dark p-4 md:p-6 rounded-xl border h-full;
  }
}
