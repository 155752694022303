.card__wrapper {
  border-radius: 6px;
}

.box-utility {
  box-shadow: 0 5px 10px 0 rgb(69 50 42 / 10%);
}

.box-utility-lg {
  box-shadow: 0 3px 16px 0 rgb(0 0 0 / 14%);
}
