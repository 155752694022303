.image-inner {
  opacity: 1;
  transform: scale(1.01);
  transition: all 0.4s ease-in-out;

  @apply w-full h-full object-center object-cover;
}

.image-inner__reveal {
  opacity: 1;
  transform: scale(1);
}

.image-overlay {
  opacity: 1;
  transition: all 0.2s ease-in-out;

  @apply w-full h-full object-center object-cover z-10;
}

.image-overlay__hide {
  opacity: 0;
}

.slider-image__container {
  @apply absolute w-full bottom-0 z-20 bg-transparent flex flex-col p-8;
}

.figure-strong__title {
  @apply text-xl sm:text-4xl transition-colors;
}

.image-wrapper {
  &.empty {
    @apply mb-8;
  }
}
