.heading-image {
  width: 100%;
  position: relative;
  height: 100vh;
}

.heading-image__blog {
  height: 70vh;
}

.heading-color {
  width: 100%;
  position: relative;
  height: 60vh;
  overflow-x: hidden;
}
