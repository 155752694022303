@import 'scroll';
@import 'height';
@import 'hover';
@import 'react-super-responsive-table';

.switch-theme-transition {
  transition: background-color;

  //@apply duration-500
}
