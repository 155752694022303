$max-width: 80rem;

.wrap-content {
  margin-right: auto;
  margin-left: auto;
  //@include xl {
  //  padding: 0;
  //}
}

.wrap-content {
  @apply max-w-7xl px-4 md:px-0;
}

.wrap-content__w60 {
  margin: 0 auto;
  max-width: 1168px;
  width: 60%;
}

.wrap-content__w80 {
  margin: 0 auto;
  max-width: 1168px;
  width: 80%;
}
