@mixin sm {
  @media only screen and (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin md {
  @media only screen and (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin lg {
  @media only screen and (min-width: $breakpoint-lg) {
    @content;
  }
}

@mixin xl {
  @media only screen and (min-width: $breakpoint-xl) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: $breakpoint-xxl) {
    @content;
  }
}

//
@mixin mobile-xs {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 575px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin desktop-lg {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin desktop-xl {
  @media (max-width: 1399px) {
    @content;
  }
}
