:root {
  --content-base-font-size: 16px;
  --content-block-text-font: 'Lora', serif;
  --content-block-heading-font-family: 'Be Vietnam', sans-serif;
  --content-block-caption-font-family: 'Be Vietnam', sans-serif;
  --content-block-size-body: 1.1em;
  --content-block-size-heading1: 1.875em;
  --content-block-size-heading2: 1.5em;
  --content-block-size-heading3: 1.25em;
  --content-block-line-height: 1.85em;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  @apply cursor-pointer mx-1 w-2 h-2 p-1 rounded-full bg-slate-500 border-none;
}

.dot:focus {
  outline: none;
}

.dot.active {
  @apply bg-dark;
}

.blog-heading-image {
  padding-bottom: 56.25%;
  &__container {
    @apply relative w-full rounded-md overflow-hidden -mt-44 mx-auto md:w-4/5;
  }

  @apply relative w-full rounded-md overflow-hidden;
}

.blog-heading-image {
  padding-bottom: 56.25%;
  &__container {
    @apply relative w-full rounded-md overflow-hidden -mt-44 mx-auto md:w-4/5;
  }

  @apply relative w-full rounded-md overflow-hidden;
}

.blog-content {
  max-width: 900px;
  margin: auto;
  font-size: var(--content-base-font-size);

  .caption .empty {
    .image-wrapper {
      &::before {
        content: '';
        position: absolute;
        height: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }

  .image-wrapper {
    &.empty {
      @apply mb-4;
    }
  }

  .image-wrapper.inner {
    position: relative;
    height: 100%;

    & > img {
      //position: absolute;
      top: 0;
      height: auto;
      width: 100%;
      z-index: 0;
    }

    &::before {
      content: '';
      position: absolute;
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0)
      );
      height: 30%;
      bottom: 0;
      width: 100%;
      z-index: 1;
    }

    .caption {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 14px;
      text-align: center;
      font-family: var(--content-block-caption-font-family);
      color: rgba(255, 255, 255, 0.86);
      z-index: 2;
    }
  }

  iframe {
    width: 100%;
    min-height: 50vh;
    margin-top: 3em;
    margin-bottom: 3em;
  }

  .layout {
    display: grid;
    gap: 20px;
    margin-top: 3em;
    margin-bottom: 3em;

    &.grid_10 {
      grid-template-columns: repeat(10, 1fr);
    }

    &.grid_12 {
      grid-template-columns: repeat(12, 1fr);
    }

    &.grid_2x2 {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }
  }

  .section {
    background-color: var(--background-page);

    &._100 {
      grid-column: span 12;
    }

    &._50_50 {
      grid-column: span 6;

      @include sm() {
        grid-column: span 12;
      }
    }

    &._30_70 {
      &:nth-child(1) {
        grid-column: span 3;
      }

      &:nth-child(2) {
        grid-column: span 7;
      }
    }

    &._70_30 {
      &:nth-child(1) {
        grid-column: span 7;
      }

      &:nth-child(2) {
        grid-column: span 3;
      }
    }

    &._33_33_33 {
      grid-column: span 4;
    }

    &._25_50_25 {
      &:nth-child(1) {
        grid-column: span 3;
      }

      &:nth-child(2) {
        grid-column: span 6;
      }

      &:nth-child(3) {
        grid-column: span 3;
      }
    }

    &._2x2_13_2_4 {
      &:nth-child(1) {
        grid-column: span 1;
        grid-row: span 2;
      }

      &:nth-child(2),
      &:nth-child(3) {
        grid-column: span 1;
        grid-row: span 1;
      }
    }
  }

  h1,
  h2,
  h3 {
    font-family: var(--content-block-heading-font-family);
    margin-bottom: 1rem;

    @apply dark:text-gray-2;
  }

  h1 {
    font-size: var(--content-block-size-heading1);
    line-height: 34px;
    font-weight: 700;
    margin-top: 2em;
  }

  h2 {
    font-size: var(--content-block-size-heading2);
    line-height: 28px;
    font-weight: 700;
    margin-top: 1.4em;
  }

  h3 {
    font-size: var(--content-block-size-heading3);
    line-height: 22px;
    font-weight: 700;
    margin-top: 1em;
  }

  a {
    color: cyan;
    cursor: pointer;
  }

  p {
    font-size: var(--content-block-size-body);
    font-weight: 500;
    line-height: var(--content-block-line-height);
    margin-bottom: 1rem;

    @apply dark:text-gray-1;
  }

  blockquote {
    border-left: solid 4px #f06a52;
    padding-left: 1em;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    margin: 3em 0;
    font-size: 1.3em;
    font-family: 'EB Garamond', serif;

    &::before {
      color: transparent;
      content: '"';
      background-image: url("data:image/svg+xml,%3Csvg width='auto' height='1em' viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.583 13.321C0.553 12.227 0 11 0 9.011C0 5.511 2.457 2.374 6.03 0.822998L6.923 2.201C3.588 4.005 2.936 6.346 2.676 7.822C3.213 7.544 3.916 7.447 4.605 7.511C6.409 7.678 7.831 9.159 7.831 11C7.831 11.9283 7.46225 12.8185 6.80587 13.4749C6.1495 14.1312 5.25926 14.5 4.331 14.5C3.258 14.5 2.232 14.01 1.583 13.321V13.321ZM11.583 13.321C10.553 12.227 10 11 10 9.011C10 5.511 12.457 2.374 16.03 0.822998L16.923 2.201C13.588 4.005 12.936 6.346 12.676 7.822C13.213 7.544 13.916 7.447 14.605 7.511C16.409 7.678 17.831 9.159 17.831 11C17.831 11.9283 17.4623 12.8185 16.8059 13.4749C16.1495 14.1312 15.2593 14.5 14.331 14.5C13.258 14.5 12.232 14.01 11.583 13.321V13.321Z' fill='%23f06a52'/%3E%3C/svg%3E%0A");
      display: inherit;
      margin-bottom: 0.5em;
      background-repeat: no-repeat;
      background-size: contain;
    }

    > p {
      margin-bottom: 0;
    }
  }

  @apply font-body;
}

.content-block-header {
  @apply mt-4
    mb-6
    col-start-2
    col-end-12
    md:col-start-2
    md:col-end-12
    lg:col-start-4
    lg:col-end-10
    font-bold
    text-3xl;
}

.content-block {
  @apply col-start-2
    col-end-12
    md:col-start-2
    md:col-end-12
    lg:col-start-4
    lg:col-end-10
    leading-9
    mb-6;
}

.content-block-media {
  @apply col-start-1
    col-end-12
    md:col-start-2
    md:col-end-12;
}

.content-block-copy {
  font-size: 1.1rem;
  line-height: 2.1rem;

  @apply col-start-1
    col-end-13
    md:col-start-2
    md:col-end-12
    lg:col-start-4
    lg:col-end-10
    mb-6
    leading-9
    font-body;
}

.content-article-wrapper {
  @apply md:col-start-2 grid grid-cols-12;
}

.content-layout-wrapper {
  @apply md:col-start-2 grid grid-cols-12 gap-6;
}

.content-layout-grid {
  @apply col-start-1
    col-end-13
    md:col-start-2
    md:col-end-12
    lg:col-start-4
    lg:col-end-10;
}

.content-layout-grid-image {
  @apply col-start-2 col-end-12 md:col-start-2 md:col-end-12 lg:col-start-2 lg:col-end-12;
}

.blog-content-wrapper {
  @include lg {
    margin: 0 auto;
    max-width: 1168px;
    width: 60%;
  }
}

.gallery-content-wrapper {
  @include lg {
    margin: 0 auto;
    max-width: 1168px;
    width: 70%;
  }
}
