//Typography
:root {
  // TYPOGRAPHY
  --font-serif: 'EB Garamond', serif;
  --font-sans: 'Be Vietnam', sans-serif;
  --font-body: 'EB Garamond', serif;
  --font-heading: 'Be Vietnam', serif;
  --font-weight-thin: 100;
  --font-weight-regular: 400;
  --font-weight-bold: 700;
  --t-heading-1: 3.052rem;
  --t-heading-2: 2.441rem;
  --t-heading-3: 1.953rem;
  --t-heading-4: 1.563rem;
  --t-heading-5: 1.25rem;
  --t-body: 1rem;
  --line-height-150: 1.5;
  --font-multiplier-100: 1;
  --font-multiplier-75: 0.75;
}

$color-primary: #f06a52;

/* RESPONSIVE BREAKPOINTS */
$breakpoint-xs: '320px';
$breakpoint-sm: '640px';
$breakpoint-md: '768px';
$breakpoint-lg: '1024px';
$breakpoint-xl: '1280px';
$breakpoint-xxl: '1536px';

$header-height: '180px';
