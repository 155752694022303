.search-input {
  width: 100%;
  padding: 0.5rem 3rem;
  margin: 0;
  height: 3rem;
  outline: none;
  font-size: 1rem;
  font-weight: 500;
  @apply border-none text-base;
}

.search-input__container {
  @apply box-utility-lg overflow-hidden rounded relative w-full;
}

.search-input__icon {
  @apply left-3 top-1/2 transform -translate-y-1/2 text-dark z-10 absolute;
}

.form-input {
  padding: 0.5rem;
  margin: 0;
  height: 2.5rem;
  outline: none;
  font-size: 1rem;
  font-weight: 500;
  &.textarea {
    @apply h-24;
  }
  @apply border-none text-base box-utility-lg overflow-hidden rounded relative;
}

.form-input__container {
  @apply flex gap-5;
}
