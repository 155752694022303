.button--primary__small {
  @apply bg-primary py-1 px-2 text-sm rounded-md uppercase font-semibold text-dark hover:text-dark font-sans;
}

.button--primary {
  @apply bg-primary py-2 px-4 rounded-md uppercase font-semibold text-dark hover:text-dark font-sans;
}

.button--primary__outline {
  @apply border border-primary py-2 px-4 rounded-xl uppercase font-semibold text-primary hover:bg-primary hover:text-white;
}

.button--primary__white {
  @apply transition duration-200 bg-transparent text-white py-2 px-4 rounded-xl uppercase font-semibold text-base border-white border-2 hover:border-primary;
}

.button--primary__dark {
  @apply transition duration-200 bg-transparent text-dark py-2 px-4 rounded-xl font-semibold text-base border-dark border-2 hover:text-gray-5 hover:border-gray-5;
}

.button--primary__tag {
  @apply font-sans transition duration-200 bg-gray-1 text-dark py-1 px-2 rounded-md text-sm border-dark hover:text-primaryDark hover:border-gray-5 bg-opacity-50;
}

.button--primary__tag-lighter {
  @apply transition duration-200 bg-transparent text-lighter py-1 px-2 rounded text-sm border-lighter border hover:text-gray-3 hover:border-gray-3 cursor-pointer transition-all;
}

.button--primary__outline-gray {
  @apply border border-gray-500 p-2 rounded-md font-semibold text-gray-500 hover:bg-gray-500 hover:text-white text-xs;
}

.button--primary__outline-gray-small {
  @apply border border-gray-500 p-2 rounded-sm font-semibold text-gray-500 hover:bg-gray-500 hover:text-white text-xs transition-all;
}

.button--primary-normal-text {
  @apply bg-primary p-2 rounded-md font-semibold text-dark hover:text-dark text-xs transition-all;
}
