// SPACING
.page-padding {
  @apply px-4 md:px-16 lg:px-24 2xl:px-40;
}

.page-padding-right-0 {
  @apply pl-4 md:pl-16 lg:pl-24 2xl:pl-40;
}

.page-vertical-padding {
  @apply py-16 md:py-32;
}

.page-vertical-padding-top {
  @apply pt-24 md:pt-32;
}

.page-vertical-padding-top-small {
  @apply pt-8 md:pt-12 xl:pt-16;
}

.page-vertical-padding-bottom {
  @apply pb-24 md:pb-32;
}

.page-vertical-padding-small {
  @apply py-12 md:py-16;
}
