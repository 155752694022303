.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.modal {
  max-width: 1000px;
  width: 80%;
  background-color: #fff;
  border-radius: 5px;
}
.modal-header {
  border-bottom: 1px solid #dedede;
}
.modal-title {
  margin: 0;
  font-size: 1.25rem;
  padding: 1rem;
  text-align: left;
}
.modal-content {
  text-align: left;
  padding: 1rem;
}
.modal-footer {
  padding: 1rem;
  border-top: 1px solid #dedede;
  text-align: right;
}
.modal-button {
  font-size: 1rem;
  font-weight: 700;
  color: #f94144;
  background: #fff;
  padding: 0;
  border-radius: 0;
  border: 0;
  margin-right: 1rem;
}
