.post-detail {
  .caption {
    font-style: italic;
  }

  .sub-caption {
    @apply mb-1 text-sm;
    font-style: italic;
    // border-left: 1px solid #000;
  }
  .grid_12 {
    display: flex;
    @apply space-x-8 mb-10;

    ._50_50 {
      flex: 1;
    }
  }

  .grid_2x2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    justify-items: stretch;
    align-items: stretch;
  }

  @apply dark:text-lighter;
}

.post-detail table {
  @apply w-full text-sm text-left px-6 overflow-scroll;
}

.post-detail thead {
  @apply text-xs text-gray-900 uppercase bg-gray-50 font-bold;
}

.post-detail th {
  @apply py-2 px-6;
}

.post-detail td {
  @apply px-4 border border-gray-6;
}
