.checkout-block-card {
  &.disabled {
    filter: blur(1px);
    @apply opacity-50 text-gray-300;
  }
  @apply md:p-8 p-4 bg-white border border-gray-200 w-full;
}

.checkout-title {
  margin: 0;
  padding-bottom: 10px;
  line-height: 1.2;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  @apply text-lg md:text-xl font-semibold text-gray-600;
}
#button-submit-all {
  transition: width 800ms ease-in;
  &.disabled {
    filter: blur(1px);
    @apply opacity-50;
  }
  @apply text-center uppercase relative;
}

.form-error {
  @apply absolute text-sm text-red-600 bottom-0 left-0;
}
