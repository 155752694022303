.marquee {
  display: flex;
  align-items: center;
  &-content {
    animation: marquee 75s linear infinite running;
  }
  &-layers {
    transition: opacity 0.5s ease-out;
  }
  &.faster {
    .marquee-content {
      animation-duration: 50s;
    }
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(-101.8%);
  }
}

@keyframes marquee-reverse {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(101.8%);
  }
}
