.full-height {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  //height: calc(var(--vh, 1vh) * 100)
}

.h-80vh {
  height: 80vh;
}

.h-70vh {
  height: 70vh;
}

.h-70vh_withHeader {
  height: calc(70vh + #{$header-height});
}

.h-60vh {
  height: 60vh;
}

.h-50vh {
  height: 50vh;
}

.sm-h-30vh {
  @include sm() {
    height: 30vh;
  }
}

.h-40vh {
  height: 40vh;
}

.min-h-30vh {
  min-height: 30vh;
}

.min-h-40vh {
  min-height: 40vh;
}

.min-h-60vh {
  min-height: 60vh;
}

.min-h-80vh {
  min-height: 80vh;
}
