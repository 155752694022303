.pages-container {
  direction: ltr;
  @apply flex-row min-h-0 flex will-change-transform flex-1;
}

.page {
  outline: none;
  @apply flex-col w-full self-stretch justify-start flex-shrink-0 h-full overflow-hidden;
}

.tab-item {
  text-rendering: optimizelegibility;
  outline: none;
  text-size-adjust: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  @apply uppercase transition duration-100 box-border text-center select-none shadow-none cursor-pointer no-underline border-none leading-normal text-sm font-semibold m-0 overflow-hidden py-2 flex-shrink-0;
}

.tab-list {
  @apply flex gap-5 flex-nowrap;
}

.tab-list__wrapper {
  top: 0px;
  border-bottom: 1.5px solid rgba(211, 207, 205, 0.4);
  @include desktop {
    top: 0px;
  }
  @apply bg-white z-10 py-4 px-0 sticky left-0 overflow-x-scroll;
}

.slider {
  @apply h-1 ml-1 mr-1 bottom-0 absolute bg-gray-50 rounded-t-lg;
}
